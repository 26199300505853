const keywordsRegex = /<p>keywords: .+?<\/p>/;

const getKeywords = html => {
  const keywordsMatchList = html.match(keywordsRegex);
  let keywords;
  if (keywordsMatchList !== null && keywordsMatchList.length > 0) {
    keywords = keywordsMatchList[0]
      .replace("<p>keywords: ", "")
      .replace("</p>", "")
      .split(",");
    keywords = keywords.map(keyword => {
      keyword.trim();
      return keyword.replace(/^\s+|\s+$/g, "");
    });
  }
  return keywords || [];
};

module.exports = { getKeywords, keywordsRegex };
