import kebabCase from "lodash.kebabcase";
import React from "react";
import styled from "styled-components";
import Image from "gatsby-image";

import { BlogMeta } from "./blog-meta";
import Constants from "../constants";
import getAuthor from "../logic/get-author";
import { rhythm } from "../utils/typography";

const BlogPostArticle = styled.article`
  margin-bottom: 1rem;
  margin-top: 0.5rem;
`;

const InnerHtml = styled.div`
  font-size: ${({ isPostPage }) => (isPostPage ? "1rem;" : "14px")};
  margin-bottom: ${rhythm(1 / 2)};
  margin-top: ${rhythm(1 / 2)};
`;

const PostHeadline = styled.h2`
  color: ${({ isPostPage }) =>
    isPostPage ? "#000" : Constants.Colors.headerTheme};
  font-size: ${({ isPostPage }) => (isPostPage ? "2rem;" : "20px")};
  margin-bottom: 0;
  :hover {
    color: ${({ isPostPage }) =>
      isPostPage ? "#000" : Constants.Colors.headerGray};
  }

  &:first-child {
    margin-top: 1rem;
  }
`;

const Smalltext = styled.small`
  @media (max-width: ${Constants.mobileWidth}) {
    font-size: 12px;
  }
`;

const Figure = styled.figure`
  margin-top: 1rem;
  text-align: center;
`;

const FigCaption = styled.figcaption`
  font-size: 13px;
  font-style: italic;
`;

const getReadingTime = minutes =>
  minutes === 1 ? "Less than 1 min read" : minutes + " min read";

const BlogArticle = ({
  avatar,
  html,
  isFirst,
  isPostPage,
  logo,
  post,
  title
}) => {
  const postUrl = `${Constants.baseUrl}/${kebabCase(post.title)}/`;
  const ogImage =
    Constants.baseUrl +
    (post.ogImg ? post.ogImg : avatar.childImageSharp.fixed.src);

  return (
    <BlogPostArticle
      itemType="https://schema.org/BlogPosting"
      itemScope={true}
      itemProp="mainEntity"
    >
      <BlogMeta
        logo={logo}
        ogImage={ogImage}
        postUrl={postUrl}
        updatedDate={post.last_updated_date}
      />
      <PostHeadline
        as={isPostPage && "h1"}
        isPostPage={isPostPage}
        itemProp="name headline"
      >
        {title}
      </PostHeadline>
      <Smalltext>
        <span itemProp="datePublished">
          {new Date(post.created_date).toDateString()}
        </span>{" "}
        <span>{"\u00b7"}</span>{" "}
        {getReadingTime(post.childMarkdownRemark.timeToRead)}{" "}
        <span>{"\u00b7"}</span>{" "}
        <span
          itemProp="author"
          itemType="https://schema.org/Person"
          itemScope={true}
        >
          By {<span itemProp="name">{getAuthor(post.owner)}</span>}{" "}
        </span>
      </Smalltext>
      {post.FirstPhoto && (
        <Figure>
          <Image
            alt={post.ogImgAlt}
            fluid={[
              post.FirstPhoto.childImageSharpMobile.fluid,
              {
                ...post.FirstPhoto.childImageSharpDesktop.fluid,
                media: `min-width: 900`
              }
            ]}
            loading={isFirst ? "eager" : "lazy"}
            title={post.ogImgAlt}
          />
          <FigCaption>{post.ogImgAlt}</FigCaption>
        </Figure>
      )}
      <InnerHtml
        dangerouslySetInnerHTML={{ __html: html }}
        itemProp="articleBody"
        isPostPage={isPostPage}
      />
    </BlogPostArticle>
  );
};

export default BlogArticle;
