import React from "react";

import Constants from "../constants";

export const BlogMeta = ({ logo, ogImage, postUrl, updatedDate }) => (
  <>
    <meta itemProp="image" content={ogImage} />
    <div
      itemScope={true}
      itemType="https://schema.org/Organization"
      itemProp="publisher"
      key="publisher"
    >
      <meta
        itemProp="name"
        content={`She & Jim`}
        key="publisherName"
        id="publisherName"
      />
      <meta
        itemProp="url"
        content={Constants.baseUrl}
        itemID={Constants.baseUrl}
        key="publisherUrl"
      />
      <div
        itemScope={true}
        itemType="https://schema.org/ImageObject"
        itemProp="logo"
        key="logo"
      >
        <meta
          itemID={`${Constants.baseUrl}${logo.childImageSharp.original.src}`}
          itemProp="url"
          content={`${Constants.baseUrl}${logo.childImageSharp.original.src}`}
          key="logoUrl"
        />
      </div>
    </div>
    <meta
      itemScope={true}
      itemProp="mainEntityOfPage"
      content={postUrl}
      itemType="https://schema.org/WebPage"
      itemID={postUrl}
      key="mainEntityOfPage"
    />
    <meta itemProp="dateModified" content={updatedDate} key="dateModified" />
  </>
);
